import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ButtonGroup from "../../utils/button/group"


const GuideCard = ({ image, guide, noMargin, isInternal }) => {
  const buttons = [
    {
      url: guide.to,
      label: "Get started",
      className: "md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px] md-max:text-sm",
      isExternal: !isInternal,
    },
    {
      url: "https://mt4.egmarkets.trade/",
      isExternal: true,
      label: "Try demo trading",
      type: "secondary",
      hasMargin: false,
      className: "md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px] md-max:text-sm"
    }
  ]

  return (
    <div
      className={`md-max:max-w-[342px] md-max:mx-auto px-[19px] md:px-[28px] pt-[19px] pb-[30px] md:!pr-[65px] md:!p-10 grid grid-cols-1 lg:grid-cols-guide-card gap-x-[50px] rounded-[11px] bg-white shadow-guide ${noMargin ? "!mb-[60px]" : ""}`}
    >
      <div className="w-full mb-[25px] md:mb-[40.5px] lg:mb-[0px] lg:w-[381px] rounded-[9px] overflow-hidden">
        <GatsbyImage image={image} className='md-max:h-[150px]' alt={guide.title} />
      </div>
      <div className="md:pt-[5px]">
        <h2
          className="capitalize text-center md:text-left text-black text-[22px] md:text-[38px] mb-5 font-bold font-dmsans">
          {guide.title}
        </h2>
        <p
          className="md:mb-[40.5px] md-max:max-w-[304px] text-center md:text-left leading-[1.6] md:leading-[30px] font-hkgrotesk text-[15px] md:text-[20px]">
          {guide.prev_text}
        </p>
        <ButtonGroup buttons={buttons} fullWidthOnMobile className={`md-max:gap-y-[20px] ${noMargin && 'md-max:mt-[18px]'}`} />
      </div>
    </div>
  )
}

export default GuideCard