import React, { useMemo } from "react"
import Layout from "../../../components/layout"
import "./index.css"
import { graphql } from "gatsby"
import tradingGuides from "../../../data/trading-guides.json"
import GuideCard from "../../../components/pages/guides/guide-card"
import guidesData from "../../../components/pages/guides/data"

const GuidesAcademyPage = ({ data }) => {

  const fluidImages = useMemo(() => {
    const files = data.allFile.nodes

    return files.reduce((images, image) => {
      images[image.name] = image.childImageSharp?.gatsbyImageData

      return images
    }, {})
  }, [data])


  return (
    <Layout
      description='Everything you need to know about trading the financial markets.'
      title="Trading Tutorials | Trading Guides"
      disableOverflow
    >
      <div className="px-6 pt-[40px] md:px-[73px] md:pt-[80px]">
        {guidesData.map(guide => <GuideCard noMargin guide={guide} image={fluidImages[guide.img]} />)}
        <h1 className="capitalize text-center md:text-left text-black text-[30px] md:text-[38px] font-bold font-dmsans mt-[100px] mb:mt-[160px] mb-[42px] md:mb-[38px]">
          Trading Guides
        </h1>
        {tradingGuides.map(guide => <GuideCard isInternal guide={guide} image={fluidImages[guide.img]} />)}
      </div>
    </Layout>
  )
}

export default GuidesAcademyPage

export const query = graphql`
{
  allFile(filter
:
  {
    relativeDirectory: {
      eq: "pages/guides/guide-card-images"
    }
  }
)
  {
    nodes
    {
      name
      childImageSharp
      {
        gatsbyImageData(quality
      :
        90, layout
      :
        FULL_WIDTH
      )
      }
    }
  }
}
`